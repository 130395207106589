import Checkbox from "../../components/Inputs/Checkbox";
import UpdateStudent from "../forms/Users/updateStudent";

export default function columnsStudents(t, selectedStudents, setSelectedStudents, data, reloadData, admin, dataFiltered) {
    var col = [
        {
            key: "givenName",
            visible: false,
            label: t("Given name"),
            id: "givenName",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.givenName}</span>,
        },
        {
            key: "surname",
            visible: false,
            label: t("Surname"),
            id: "surname",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.surname}</span>,
        },
        {
            key: "displayName",
            visible: true,
            label: t("Name"),
            id: "displayName",
            exportableCSV: true,
            width: 300,
            format: (row) => data ?
                <UpdateStudent id={row?.id}
                    TriggerComponent={(onClick) =>
                        <span className="text-green-500 font-weight-bolder mb-0 text-md cursor-pointer" onClick={onClick}>
                            {row.displayName}
                        </span>}
                    reloadData={reloadData}
                />
                : <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
                    {row.displayName}
                </span>
        },
        {
            key: "userPrincipalName",
            visible: true,
            label: t("ID 365"),
            id: "userPrincipalName",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.userPrincipalName}</span>,
        },
        {
            key: "officeLocation",
            visible: true,
            label: t("Classroom"),
            id: "officeLocation",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.officeLocation}</span>,
            filter: {
                type: "select"
            }
        },
        {
            key: "createdDateTime",
            visible: true,
            label: t("Creation date"),
            id: "createdDateTime",
            exportableCSV: true,
            width: 300,
            format: (row) => {
                if (row.createdDateTime) {
                    var date = new Date(row.createdDateTime);
                    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth()+1) : date.getMonth() +1;
                    return <span>{day + "/" + month + "/" + (new Date(row.createdDateTime)).getFullYear()}</span>
                } else {
                    return null;
                }
            },
            filter: {
                type: "date"
            }
        },
    ];

    if (dataFiltered) {
        col.unshift({
            key: "checkbox",
            visible: true,
            exportableCSV: false,
            label: () => <Checkbox
                value={selectedStudents.length === dataFiltered.length}
                onClick={() => {
                    if (selectedStudents.length === dataFiltered.length) {
                        setSelectedStudents([]);
                    } else {
                        setSelectedStudents(dataFiltered ? dataFiltered.map(s => s.id) : [])
                    }
                }} />,
            id: "checkbox",
            width: 300,
            format: (row) => (
                <Checkbox
                    value={selectedStudents ? selectedStudents.find(s => s === row?.id) : false}
                    onClick={() => {
                        if (selectedStudents.find(s => s === row?.id)) {
                            setSelectedStudents(selectedStudents.filter(s => s !== row?.id));
                        } else {
                            var selectedStudentsNew = selectedStudents
                            setSelectedStudents([])
                            selectedStudentsNew.push(row?.id)
                            setSelectedStudents(selectedStudentsNew)
                        }
                    }} />
            ),
        })
    }

    if (admin) {
        col.push({
            key: "school",
            visible: false,
            label: t("School"),
            id: "school",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.school}</span>,
        });
        col.push({
            key: "state",
            visible: false,
            label: t("UAI"),
            id: "state",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.state}</span>,
        })
    }

    return col
}