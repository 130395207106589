import React, { useEffect } from "react";

// language
import { I18nextProvider } from "react-i18next";
import i18n from "./locale/i18n";
import Router from "./utils/router/router";
export const MSLicencesContext = React.createContext()

const parseCSV = (arr) => {
  var jsonObj = [];
  arr = arr.replace("\r", "").split("\n")
  for (var i = 1; i < arr.length; i++) {
    var data = arr[i].split(',');
    if (!jsonObj.find(jo => jo.skuId === data[2])) {
      var obj = {};
      obj["skuId"] = data[2];
      obj["name"] = data[0];
      jsonObj.push(obj);
    }
  }
  return jsonObj
}

function App() {
  const [csvData, setCsvData] = React.useState([])
  useEffect(() => {
    fetch('/assets/doc/licences.csv')
      .then(response => response.text())
      .then(data => {
        setCsvData(parseCSV(data))
      })
      .catch(error => console.error('Error fetching CSV:', error));
  }, [])
  return (
    <I18nextProvider i18n={i18n}>
        <MSLicencesContext.Provider value={csvData}>
          <Router />
        </MSLicencesContext.Provider>
    </I18nextProvider>
  );
}

export default App;



