import { getAccessToken, getAccessTokenGraph } from "../../auth/verifyAuth";

async function getTenantLicenses() {
    var accessToken = await getAccessTokenGraph();
    const url = 'https://graph.microsoft.com/beta/subscribedSkus';
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.value;
    } catch (error) {
        console.error('Error fetching tenant licenses:', error);
        throw error;
    }
}

async function upsertLicence(schoolId, licenceId, amount) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/licences/" + schoolId, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_licence: licenceId, amount: amount }),
    })
}

async function deleteLicence(schoolId, licenceId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/licences/" + schoolId + "/" + licenceId, {
        method: "DELETE",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
}

async function assignLicence(userId, licenceId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/assignLicence/" + licenceId, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: [userId] }),
    })
}

async function removeLicence(userId, licenceId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/removeLicence/" + licenceId, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: [userId] }),
    })

}

async function assignLicenceUsers(usersId, licenceId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/assignLicence/" + licenceId, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: usersId }),
    })
}

async function removeLicenceUsers(usersId, licenceId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/removeLicence/" + licenceId, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: usersId }),
    })

}

export { getTenantLicenses, upsertLicence, deleteLicence, assignLicence, removeLicence, assignLicenceUsers, removeLicenceUsers}