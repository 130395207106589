import cogoToast from "cogo-toast";
import { getAccessToken, getAccessTokenGraph } from "../../auth/verifyAuth";

async function getGroups(schoolsCity, setAmount) {
    var accessToken = await getAccessTokenGraph();
    var request = "https://graph.microsoft.com/beta/groups?";

    if (schoolsCity && schoolsCity.length) {
        request += "$search=";
        schoolsCity.forEach((city, index) => {
            if (city.includes("'")) {
                var fragmentedcity = city.split("'");
                city = "";
                city = Object.keys(fragmentedcity).join("''")
            }
            if (index + 1 !== schoolsCity.length) {
                request += "\"displayName:" + city.replaceAll(" ","") + "\" OR "
                request += "\"displayName:" + city + "\" OR "
            } else {
                request += "\"displayName:" + city + "\" OR "
                request += "\"displayName:" + city.replaceAll(" ","") + "\""
            }
        });
        request += "&"
    }
    request += "$select=id,displayName,description,nickname,mail,mailNickname&$count=true";
    return fetch(request, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "ConsistencyLevel": "eventual"
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res) {
            if (setAmount) {
                setAmount(res["@odata.count"])
            }
            if (res["@odata.nextLink"]) {
                return handleNextLink(res["@odata.nextLink"], accessToken, res.value);
            } else {
                var result = res.value;
            result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
            return result;
            }
        }
    });
}

async function handleNextLink(nextLink, accessToken, data) {
    return fetch(nextLink, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        var dataUpdated = data;
        if (res && res.value) {
            dataUpdated = dataUpdated.concat(res.value);
        }
        if (res && res["@odata.nextLink"]) {
            return handleNextLink(res["@odata.nextLink"], accessToken, dataUpdated);
        } else {
            var result = dataUpdated;
        result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
        return result;
        }
    })
}

async function getGroupsByIds(groupsId, setAmount) {
    var accessToken = await getAccessTokenGraph();

    if (groupsId && groupsId.length) {
        var query = "https://graph.microsoft.com/beta/groups?$filter=id in ("
        groupsId.forEach((id, index) => {
            if (index + 1 !== groupsId.length)
                query += "'" + id + "',";
            else
                query += "'" + id + "'";
        })
        query += ")";

        return fetch(query + "&$count=true", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "ConsistencyLevel": "eventual"
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
        }).then(res => {
            if (res) {
                if (setAmount) {
                    setAmount(res["@odata.count"])
                }
                if (res["@odata.nextLink"]) {
                    return handleNextLink(res["@odata.nextLink"], accessToken, res.value);
                } else {
                    var result = res.value;
            result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
            return result;
                }
            }
        })
    } else {
        return [];
    }
}


async function getGroup(id) {
    var accessToken = await getAccessTokenGraph();
    return fetch("https://graph.microsoft.com/beta/groups/" + id, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => res ? res.value : null);
}


async function getGroupMembers(id) {
    var accessToken = await getAccessTokenGraph();
    return fetch("https://graph.microsoft.com/beta/groups/" + id + "/members", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if(res){
            var result = res.value;
            result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
            return result;
        } else {
            return [];
        }
    });
}

async function getGroupOwners(id) {
    var accessToken = await getAccessTokenGraph();
    return fetch("https://graph.microsoft.com/beta/groups/" + id + "/owners", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if(res){
            var result = res.value;
            result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
            return result;
        } else {
            return [];
        }
    });
}

async function postGroup(group) {
    var accessToken = await getAccessToken();

    group["template@odata.bind"] = "https://graph.microsoft.com/beta/teamsTemplates('" + group.template + "')";
    group["owners@odata.bind"] = ["https://graph.microsoft.com/beta/users('" + group.owner + "')"];

    return fetch(window.location.origin + "/api/v1.0/group", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(group),
    })
}

async function postGroupMember(id, groupId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/groupmember/" + groupId + "/" + id, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
}

async function removeGroupMember(id, groupId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/groupmember/" + groupId + "/" + id, {
        method: "DELETE",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
}

async function postGroupOwner(id, groupId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/groupowner/" + groupId + "/" + id, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
}

async function removeGroupOwner(id, groupId) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/groupowner/" + groupId + "/" + id, {
        method: "DELETE",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
}

async function deleteGroups(groupsId, t) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/group", {
        method: "DELETE",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: groupsId }),
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            cogoToast.error(
                t("Error on delete groups") + " : " + res.status,
                { position: "bottom-right" }
            )
        }
    })
}

async function postGroups(groups) {
    var accessToken = await getAccessToken();


    return fetch(window.location.origin + "/api/v1.0/groups", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(groups),
    })
}

export {
    getGroups,
    getGroup,
    getGroupMembers,
    getGroupOwners,
    postGroupMember,
    postGroup,
    deleteGroups,
    getGroupsByIds,
    removeGroupMember,
    postGroupOwner,
    removeGroupOwner,
    postGroups
}