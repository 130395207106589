import React, { useContext, useEffect, useState } from "react";
import Structure from "../../../components/Forms/Structure";
import { MSLicencesContext } from "../../../App";
import Button from "../../../components/Buttons/Button";
import { withTranslation } from "react-i18next";
import { getTenantLicenses, upsertLicence } from "../../requests/licences";
import cogoToast from "cogo-toast";

function AddLicence({ t, school, returnFromForm }) {
    const MSLicencesCSV = useContext(MSLicencesContext)
    const [licences, setLicences] = useState([])
    const [createNewLicence, setCreateNewLicence] = useState({ licence: null, amount: 0 })

    useEffect(() => {
        getTenantLicenses().then(res => {
            setLicences(res)
        })
    }, [])

    const handleCreateLicence = () => {
        if (!createNewLicence.licence || !createNewLicence.amount) {
            cogoToast.error(t("Veuillez remplir tous les champs"),
            { position: "bottom-right" })
            return
        }
        upsertLicence(school.uai, createNewLicence.licence, createNewLicence.amount).then(res => {
            if (res.ok) {
                returnFromForm(createNewLicence)
                cogoToast.success(t("Licence ajoutée avec succès"),
                { position: "bottom-right" })
            } else {
                cogoToast.error(t("Erreur lors de l'ajout de la licence"),
                { position: "bottom-right" })
            }
        }).catch(() => { cogoToast.error(t("Erreur lors de l'ajout de la licence"),
            { position: "bottom-right" }) })
    }

    return <div className="sm:py-0 sm:m-8 sm:border">
        <h1 className="font-semibold leading-6 text-gray-600 text-base flex justify-center py-8">Ajouter une licence</h1>
        <Structure
            id="licence"
            title={t("Choix de la licence")}
            component={() => (
                <select
                    name="licence"
                    id="input-licence"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    onChange={e => { setCreateNewLicence({ licence: e.target.value, amount: 0 }); }}
                >
                    {licences.filter(l => l.appliesTo === "User" && l.capabilityStatus === "Enabled" && l && !school?.licences?.find(li => li.id_licence === l.skuId) && !l.skuPartNumber.includes("STUDENT")).sort((a, b) => a.skuPartNumber > b.skuPartNumber ? 1 : -1).map(licence =>
                        <option key={licence.skuId} value={licence.skuId}>
                            {MSLicencesCSV.find(cd => cd.skuId === licence.skuId) ? MSLicencesCSV.find(cd => cd.skuId === licence.skuId).name : licence.skuPartNumber} ({licence.consumedUnits}/{licence.prepaidUnits.enabled})
                        </option>)}
                </select>
            )} />

        <Structure
            id="amount"
            title={"Nombre de licences (max : " + (!isNaN(licences.find(li => li.skuId === createNewLicence.licence)?.prepaidUnits?.enabled - licences.find(li => li.skuId === createNewLicence.licence)?.consumedUnits) ? (licences.find(li => li.skuId === createNewLicence.licence)?.prepaidUnits?.enabled - licences.find(li => li.skuId === createNewLicence.licence)?.consumedUnits) : 0) + ")"}
            component={() => (
                <input
                    name="amount"
                    id="input-amount"
                    type="number"
                    min={0}
                    defaultValue={createNewLicence.amount}
                    disabled={!createNewLicence.licence}
                    // max={licences.find(li => li.skuId === createNewLicence.licence)?.prepaidUnits?.enabled - licences.find(li => li.skuId === createNewLicence.licence)?.consumedUnits}
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    onChange={(event) => {
                        if (event && event.target && event.target.value) {
                            var am = event.target.value
                            setCreateNewLicence(cnl => {
                                return { licence: cnl.licence, amount: am }
                            })
                        }

                    }}
                />
            )} />
        <div className="flex items-center justify-center py-4">
            <button
                type="button"
                className="bg-white py-2 px-4 mr-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => { returnFromForm(false) }}
            >
                {t("Cancel")}
            </button>
            <Button
                type="button"
                disabled={!createNewLicence.licence && !createNewLicence.amount}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => {
                    handleCreateLicence()
                }}
            >
                {t("Ajouter")}
            </Button>
        </div>
    </div>
}

export default React.memo(withTranslation()(AddLicence));