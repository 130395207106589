import React, { useContext, useState } from "react";
import { withTranslation } from "react-i18next";
import { deleteLicence, getTenantLicenses, upsertLicence } from "../../requests/licences";
import cogoToast from "cogo-toast";
import { TrashIcon } from "@heroicons/react/outline";
import { MSLicencesContext } from "../../../App";

function HandleLicence({ t, school, licence, returnFromForm }) {
    const MSLicencesCSV = useContext(MSLicencesContext);
    const [amount, setAmount] = useState(licence?.amount);
    const [licences, setLicences] = useState([])
    const [mode, setMode] = useState("view")

    const handleUpdateLicence = () => {
        if (amount === licence.amount) {
            setMode("view");
            return;
        } else {
            upsertLicence(school.uai, licence.id_licence, amount).then(res => {
                if (res.ok) {
                    cogoToast.success(t("Licence mise à jour avec succès"),
                    { position: "bottom-right" })
                    setMode("view");
                    returnFromForm({ id_licence: licence.id_licence, amount: amount })
                } else {
                    cogoToast.error(t("Erreur lors de la mise à jour de la licence"),
                    { position: "bottom-right" })
                }
            }).catch(() => { cogoToast.error(t("Erreur lors de la mise à jour de la licence"),
                { position: "bottom-right" }) })
        }
    }

    const handleDeleteLicence = () => {
        deleteLicence(school.uai, licence.id_licence).then(res => {
            if (res.ok) {
                cogoToast.success(t("Licence supprimée avec succès"),
                { position: "bottom-right" })
                returnFromForm({ id_licence: licence.id_licence, amount: 0 })
            } else {
                cogoToast.error(t("Erreur lors de la suppression de la licence"),
                { position: "bottom-right" })
            }
        }).catch(() => { cogoToast.error(t("Erreur lors de la suppression de la licence"),
            { position: "bottom-right" }) })
    }

    return <li key={licence.id_licence} className="py-5 mx-3 border-b border-gray-100">
        
        {mode === "edit" ?
            <label
                className="block text-sm font-medium text-gray-800 sm:mt-px sm:pt-2 ml-4"
            >
                {"Sélectionnez le nombre de licences à modifier (max : " + (!isNaN(licences.find(li => li.skuId === licence.id_licence)?.prepaidUnits?.enabled - licences.find(li => li.skuId === licence.id_licence)?.consumedUnits) ? (licences.find(li => li.skuId === licence.id_licence)?.prepaidUnits?.enabled - licences.find(li => li.skuId === licence.id_licence)?.consumedUnits) : 0) + ")"}
            </label> : null}
<div className="flex items-center justify-between gap-x-6">
        <div className="flex items-center">
            {mode === "view" ? <div className="ml-8 mr-8 text-base text-gray-900 text-5xl font-bold tracking-tight">{licence.amount}</div> :
                <div>
                    <input
                        type="number"
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                        min={0}
                        max={licences.find(li => li.skuId === licence.id_licence)?.prepaidUnits?.enabled - licences.find(li => li.skuId === licence.id_licence)?.consumedUnits}
                        className="ml-8 mr-8 text-base text-gray-900 text-5xl font-bold tracking-tight w-32 border border-gray-300 rounded-md"
                    /></div>}
            <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                    <p className="font-semibold leading-6 text-gray-600 text-base">{MSLicencesCSV.find(cd => cd.skuId === licence.id_licence) ? MSLicencesCSV.find(cd => cd.skuId === licence.id_licence).name : licence.label}</p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                        {licence.id_licence}
                    </p>
                </div>
            </div>
        </div>

        <div className="flex flex-none items-center gap-x-4">

            <div
                className="cursor-pointer rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                onClick={() => {
                    if (mode === "view") {
                        getTenantLicenses().then(res => {
                            setLicences(res)
                        })
                    } setMode(m => m === "view" ? "edit" : "view")
                }}
            >
                {mode === "view" ? t("Editer") : t("Annuler")}
            </div>
            {mode === "edit" && <div
                className="cursor-pointer rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm bg-green-600 sm:block"
                onClick={handleUpdateLicence}
            >
                {t("Enregistrer")}
            </div>}
            {mode === "view" && <TrashIcon className="h-8 w-8 text-red-500 cursor-pointer" onClick={() => { handleDeleteLicence() }} />}
        </div>
        </div>
    </li>
}

export default React.memo(withTranslation()(HandleLicence));