import UpdateSchool from "../forms/School/updateSchool";

export default function columnsSchools(t, isForm, reloadData) {
    return [
        {
            key: "uai",
            visible: true,
            label: t("UAI"),
            id: "uai",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.uai}</span>,
        },
        {
            key: "intitule",
            visible: true,
            label: t("Intitule"),
            id: "intitule",
            exportableCSV: true,
            width: 300,
            format: (row) => !isForm ?
                <UpdateSchool TriggerComponent={(onClick) =>
                    <span className="text-green-500 font-weight-bolder mb-0 text-md cursor-pointer" onClick={onClick}>
                        {row.intitule}
                    </span>}
                    id={row.uai}
                    reloadData={reloadData} /> : <span className="text-primary font-weight-bolder mb-0 text-md ">
                    {row.intitule}
                </span>
        },
        {
            key: "domaine",
            visible: true,
            exportableCSV: true,
            label: t("Domain"),
            id: "domaine",
            width: 300,
            format: (row) => <span>{row.domaine}</span>,
        },
        {
            key: "ville",
            visible: true,
            exportableCSV: true,
            label: t("City"),
            id: "ville",
            width: 300,
            format: (row) => <span>{row.ville}</span>,
        },
        {
            key: "nom_fournisseur",
            visible: true,
            exportableCSV: true,
            label: t("Fournisseur"),
            id: "nom_fournisseur",
            width: 300,
            format: (row) => <span>{row.nom_fournisseur}</span>,
        },
        {
            key: "date_souscription",
            visible: true,
            exportableCSV: true,
            label: t("Date de souscription"),
            id: "date_souscription",
            width: 300,
            format: (row) => {
                if (row.date_souscription) {
                    var date = new Date(row.date_souscription);
                    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth()+1) : date.getMonth() +1;
                    return <span>{day + "/" + month + "/" + (new Date(row.date_souscription)).getFullYear()}</span>
                } else {
                    return null;
                }
            },
            filter: {
                type: "date"
            }
        },
        {
            key: "administrateur",
            visible: true,
            exportableCSV: true,
            label: t("admin"),
            id: "administrateur",
            width: 300,
            format: (row) => <span>{row.administrateur}</span>,
        },
        {
            key: "responsable",
            visible: true,
            exportableCSV: true,
            label: t("responsable"),
            id: "responsable",
            width: 300,
            format: (row) => <span>{row.responsable}<span className="text-gray-500">{row.responsable_role ? " (" + (row.responsable_role === 1 ? "Administrateur" : "Responsable") + ")" : null}</span></span>,
        },
    ];
}