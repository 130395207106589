import Button from "../../components/Buttons/Button";

export default function ColumnLicence(t, MSLicencesCSV, deleteLicence) {
    return [
        {
            key: "name",
            visible: true,
            exportableCSV: true,
            label: t("Name"),
            id: "name",
            width: 300,
            format: (row) => (
                <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
                    {MSLicencesCSV.find(cd => cd.skuId === row.skuId) ? MSLicencesCSV.find(cd => cd.skuId === row.skuId).name : row.skuId}
                </span>

            ),
        },
        {
            key: "delete",
            visible: true,
            exportableCSV: false,
            label: "",
            id: "delete",
            format: (row) => (
                <div className="flex justify-end">
               <Button className="" type="button" onClick={() => deleteLicence(row.skuId )}>{t("Delete")}</Button>
               </div>
            ),
        }
    ];
}