import React, { useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

// apis requests
import { getMe, getStaffs, getStudents, isUserAdmin } from "../utils/requests/user";
import { getSchool, getSchools } from "../utils/requests/school";
import { getGroups } from "../utils/requests/group";

// import components
import Table from "../components/Tables/Table";
import TabsUnderline from "../components/Tabs/TabsUnderline";
import Card from "../components/Card/Card";
import Button from "../components/Buttons/Button";

// import tables columns
import columnsStudents from "../utils/tables/Students";
import columnsStaffs from "../utils/tables/Staffs";
import columnsGroups from "../utils/tables/Groups";
import columnsSchools from "../utils/tables/Schools";

// import forms
import CreateStudent from "../utils/forms/Users/createStudent";
import CreateStaff from "../utils/forms/Users/createStaff";
import ResetPassword from "../utils/forms/Users/resetPasswordUsers";
import DeleteUsersForm from "../utils/forms/Users/deleteUsers";
import CreateGroup from "../utils/forms/Group/createGroup";
import DeleteGroups from "../utils/forms/Group/deleteGroups";
import CreateSchool from "../utils/forms/School/createSchool";
import ImportStudent from "../utils/forms/Users/importStudent";
import Select from "../components/Inputs/Select";
import ImportGroup from "../utils/forms/Group/importGroup";
import { MSLicencesContext } from "../App";
import columnsSchoolLicences from "../utils/tables/SchoolLicences";
import HandleLicenceUsers from "../utils/forms/Users/handleLicenceUsers";

// const
function tabs(t, admin, isResponsable, school) {
  var tabsStandard = [
    {
      id: "students",
      name: t("Students"),
    }
    ,
    {
      id: "groups",
      name: t("Groups"),
    },
  ];

  if (!isResponsable || admin) {
    tabsStandard.push({
      id: "staffs",
      name: t("Staffs"),
    })
  }

  if (school && school.licences && school.licences.length > 0) {
    tabsStandard.push({
      id: "licences",
      name: t("Licences"),
    });
  }
  if (admin) {
    tabsStandard.push({
      id: "schools",
      name: t("Schools"),
    });
  }
  return tabsStandard;
}

function Main({ t }) {
  const MSLicencesCSV = useContext(MSLicencesContext)

  const [initOnce, setInitOnce] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [isResponsable, setIsResponsable] = useState(true);
  const [school, setSchool] = useState({});
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [amountStudents, setAmountStudents] = useState(0);
  const [amountStaffs, setAmountStaffs] = useState(0);
  const [amountGroups, setAmountGroups] = useState(0);
  const [amountSchools, setAmountSchools] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabs(t)[0].id);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [actionsBar, setActionsBar] = useState([]);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [loadingStaff, setLoadingStaff] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [loadingSchool, setLoadingSchool] = useState(false);

  const handleGroup = useCallback(
    () => {
      setLoadingGroup(true);
      setGroups([]);
      setSelectedGroups([]);
      setAmountGroups(0);
      isUserAdmin().then((isAdmin) => {
        if (isAdmin) {
          getSchools().then((schools) => {
            if (schools && schools.length) {
              var schoolPreselected = schools.find(
                (sch) => sch.uai === school.uai
              );
              getGroups(
                schools.map((sch) => sch.ville),
                setAmountGroups,
                false
              );
              if (schoolPreselected && schoolPreselected.ville) {
                getGroups([schoolPreselected.ville], null, true).then(
                  (groupsAPI) => {
                    if (groupsAPI && groupsAPI.length) {
                      setGroups(groupsAPI);
                    }
                    setLoadingGroup(false);
                  }
                );
              } else {
                setLoadingGroup(false);
              }
            } else {
              setLoadingGroup(false);
            }
          });
        } else {
          getSchool().then((school) => {
            if (school && school.ville) {
              getGroups([school.ville], setAmountGroups, true).then(
                (groupssAPI) => {
                  if (groupssAPI && groupssAPI.length) {
                    setGroups(groupssAPI);
                  }
                  setLoadingGroup(false);
                }
              );
            } else {
              setLoadingGroup(false);
            }
          });
        }
        actions(t, selectedTab, []);
      });


    },
    [school]
  );

  const handleSchool = useCallback(() => {
    setSchools([]);
    if (admin) {
      getSchools().then((schoolsAPI) => {
        setSchools(schoolsAPI);
        setAmountSchools(schoolsAPI.length);
      });
    }
  }, [admin]);

  const handleStudents = useCallback(
    (data) => {
      var studentsUpdated = students;
      setSelectedStudents([]);

      if (data && data.length) {
        setStudents([]);
        data.forEach((d) => {
          if (d?.deleted) {
            studentsUpdated = studentsUpdated.filter((s) => s.id !== d?.id);
          } else {
            var index = studentsUpdated.findIndex((s) => s.id === d?.id);
            if (index !== -1) {
              studentsUpdated[index] = d;
            } else {
              studentsUpdated.push(d);
            }
          }
        });

        setStudents(studentsUpdated);
      }
    },
    [students]
  );

  const handleStaffs = useCallback(
    (data) => {
      setSelectedStaffs([]);
      if (!isResponsable || admin) {
        var staffsUpdated = staffs;

        if (data && data.length) {
          setStaffs([]);
          data.forEach((d) => {
            if (d?.deleted) {
              staffsUpdated = staffsUpdated.filter((s) => s.id !== d?.id);
            } else {
              var index = staffsUpdated.findIndex((s) => s.id === d?.id);
              if (index !== -1) {
                staffsUpdated[index] = d;
              } else {
                staffsUpdated.push(d);
              }
            }
          });
          setStaffs(staffsUpdated);
        }
      }
    },
    [staffs, isResponsable, admin]
  );

  const actions = useCallback(
    (t, tab, selectedItems) => {
      setActionsBar([]);
      var multipleSelection = selectedItems && selectedItems.length;
      var render = [];
      switch (tab) {
        case "students":
          render.push(
            <Button
              key="refreshStudents"
              loading={loadingStudent}
              onClick={() => {
                setLoadingStudent(true);
                setStudents([]);
                setSelectedStudents([]);
                setAmountStudents(0);
                isUserAdmin().then((isAdmin) => {
                  if (isAdmin) {
                    getSchools().then((schools) => {
                      if (schools && schools.length) {
                        var schoolPreselected = schools.find(
                          (sch) => sch.id === school.id
                        );
                        getStudents(
                          schools.map((sch) => sch.uai),
                          setAmountStudents,
                          false
                        );
                        if (schoolPreselected && schoolPreselected.uai) {
                          getStudents([schoolPreselected.uai], null, true).then(
                            (studentsAPI) => {
                              if (studentsAPI && studentsAPI.length) {
                                studentsAPI.map((s) => {
                                  s.school = schools.find(
                                    (sch) => sch.uai === s.state
                                  )
                                    ? schools.find((sch) => sch.uai === s.state)
                                      .intitule
                                    : null;
                                  return s;
                                });
                                setStudents(studentsAPI);
                              }
                              setLoadingStudent(false);
                            }
                          );
                        } else {
                          setLoadingStudent(false);
                        }
                      } else {
                        setLoadingStudent(false);
                      }
                    });
                  } else {
                    getSchool().then((school) => {
                      if (school && school.uai) {
                        getStudents([school.uai], setAmountStudents, true).then(
                          (studentsAPI) => {
                            if (studentsAPI && studentsAPI.length) {
                              setStudents(studentsAPI);
                            }
                            setLoadingStudent(false);
                          }
                        );
                      } else {
                        setLoadingStudent(false);
                      }
                    });
                  }
                });
              }}
            >
              {t("Refresh")}
            </Button>
          );
          if (multipleSelection) {
            render.push(
              <ResetPassword
                key="ResetPassword"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>
                    {t("Reset password")}
                  </Button>
                )}
                usersId={selectedItems}
              />
            );
            render.push(
              <DeleteUsersForm
                key="deleteusersform"
                TriggerComponent={(onClick, loading) => (
                  <Button loading={loading} onClick={() => onClick()}>
                    {t("Delete")}
                  </Button>
                )}
                usersId={selectedItems}
                reloadData={(data) => {
                  handleStudents(data);
                  actions(t, selectedTab, []);
                }}
              />
            );
          } else {
            render.push(
              <CreateStudent
                key="CreateStudent"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>{t("Add")}</Button>
                )}
                reloadData={(data) => {
                  handleStudents(data);
                  actions(t, selectedTab, []);
                }}
              />
            );
            render.push(
              <ImportStudent
                key="importStudent"
                TriggerComponent={(onClick, loading) => (
                  <Button loading={loading} onClick={() => onClick()}>
                    {t("Import")}
                  </Button>
                )}
                reloadData={(data) => {
                  handleStudents(data);
                  actions(t, selectedTab, []);
                }}
              />
            );
          }
          setActionsBar(render);
          break;
        case "staffs":
          render.push(
            <Button
              key="refreshStaffs"
              loading={loadingStaff}
              onClick={() => {
                setLoadingStaff(true);
                setStaffs([]);
                setSelectedStaffs([]);
                setAmountStaffs(0);
                isUserAdmin().then((isAdmin) => {
                  if (isAdmin) {
                    getSchools().then((schools) => {
                      if (school && school.uai && schools && schools.length) {
                        var schoolPreselected = schools.find(
                          (sch) => sch.uai === school.uai
                        );
                        getStaffs(
                          schools.map((sch) => sch.uai),
                          setAmountStaffs,
                          false
                        );
                        if (schoolPreselected && schoolPreselected.uai) {
                          getStaffs([schoolPreselected.uai], null, true).then(
                            (staffsAPI) => {
                              if (staffsAPI && staffsAPI.length) {
                                staffsAPI.map((s) => {
                                  s.school = schools.find(
                                    (sch) => sch.uai === s.state
                                  )
                                    ? schools.find((sch) => sch.uai === s.state)
                                      .intitule
                                    : null;
                                  return s;
                                });
                                setStaffs(staffsAPI);
                              }
                              setLoadingStaff(false);
                            }
                          );
                        } else {
                          setLoadingStaff(false);
                        }
                      } else {
                        setLoadingStaff(false);
                      }
                    });
                  } else {
                    getSchool().then((school) => {
                      if (school && school.uai) {
                        getStaffs([school.uai], setAmountStaffs, true).then(
                          (staffsAPI) => {
                            if (staffsAPI && staffsAPI.length) {
                              setStaffs(staffsAPI);
                            }
                            setLoadingStaff(false);
                          }
                        );
                      } else {
                        setLoadingStaff(false);
                      }
                    });
                  }
                  actions(t, selectedTab, []);
                });
              }}
            >
              {t("Refresh")}
            </Button>
          );
          if (multipleSelection) {
            if (school.licences && school.licences.length > 0) {
              render.push(
                <HandleLicenceUsers
                  key="licencesUsers"
                  TriggerComponent={(onClick) => (
                    <Button onClick={() => onClick()}>
                      {t("Gérer les licences")}
                    </Button>
                  )}
                  school={school}
                  usersId={selectedItems}
                  handleReturn={() => {
                    setSelectedStaffs([]);
                    isUserAdmin().then((isAdmin) => {
                      if (isAdmin) {
                        getSchools().then((schools) => {
                          if (school && school.uai && schools && schools.length) {
                            var schoolPreselected = schools.find(
                              (sch) => sch.uai === school.uai
                            );
                            getStaffs(
                              schools.map((sch) => sch.uai),
                              setAmountStaffs,
                              false
                            );
                            if (schoolPreselected && schoolPreselected.uai) {
                              getStaffs([schoolPreselected.uai], null, true).then(
                                (staffsAPI) => {
                                  if (staffsAPI && staffsAPI.length) {
                                    staffsAPI.map((s) => {
                                      s.school = schools.find(
                                        (sch) => sch.uai === s.state
                                      )
                                        ? schools.find((sch) => sch.uai === s.state)
                                          .intitule
                                        : null;
                                      return s;
                                    });
                                    setStaffs(staffsAPI);
                                  }
                                  setLoadingStaff(false);
                                }
                              );
                            } else {
                              setLoadingStaff(false);
                            }
                          } else {
                            setLoadingStaff(false);
                          }
                        });
                      } else {
                        getSchool().then((school) => {
                          if (school && school.uai) {
                            getStaffs([school.uai], setAmountStaffs, true).then(
                              (staffsAPI) => {
                                if (staffsAPI && staffsAPI.length) {
                                  setStaffs(staffsAPI);
                                }
                                setLoadingStaff(false);
                              }
                            );
                          } else {
                            setLoadingStaff(false);
                          }
                        });
                      }
                      actions(t, selectedTab, []);
                    });
                  }}
                />
              );
            }
            render.push(
              <ResetPassword
                key="resetpswdstaff"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>
                    {t("Reset password")}
                  </Button>
                )}
                usersId={selectedItems}
              />
            );
            render.push(
              <DeleteUsersForm
                key="deletestaffs"
                TriggerComponent={(onClick, loading) => (
                  <Button loading={loading} onClick={() => onClick()}>
                    {t("Delete")}
                  </Button>
                )}
                usersId={selectedItems}
                reloadData={() => {
                  var staffsUpdated = staffs.filter(
                    (s) => !selectedItems.find((it) => it === s.id)
                  );
                  setSelectedStaffs([]);
                  setStaffs(staffsUpdated);
                }}
              />
            );
          } else {
            render.push(
              <CreateStaff
                key="createstaff"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>{t("Add")}</Button>
                )}
                reloadData={(data) => {
                  handleStaffs(data);
                  actions(t, selectedTab, []);
                }}
              />
            );
          }
          setActionsBar(render);
          break;
        case "groups":
          render.push(
            <Button
              key="refreshGroups"
              loading={loadingGroup}
              onClick={() => {
                setLoadingGroup(true);
                setGroups([]);
                setSelectedGroups([]);
                setAmountGroups(0);
                isUserAdmin().then((isAdmin) => {
                  if (isAdmin) {
                    getSchools().then((schools) => {
                      if (schools && schools.length) {
                        var schoolPreselected = schools.find(
                          (sch) => sch.uai === school.uai
                        );
                        getGroups(
                          schools.map((sch) => sch.ville),
                          setAmountGroups,
                          false
                        );
                        if (schoolPreselected && schoolPreselected.ville) {
                          getGroups([schoolPreselected.ville], null, true).then(
                            (groupsAPI) => {
                              if (groupsAPI && groupsAPI.length) {
                                setGroups(groupsAPI);
                              }
                              setLoadingGroup(false);
                            }
                          );
                        } else {
                          setLoadingGroup(false);
                        }
                      } else {
                        setLoadingGroup(false);
                      }
                    });
                  } else {
                    getSchool().then((school) => {
                      if (school && school.ville) {
                        getGroups([school.ville], setAmountGroups, true).then(
                          (groupssAPI) => {
                            if (groupssAPI && groupssAPI.length) {
                              setGroups(groupssAPI);
                            }
                            setLoadingGroup(false);
                          }
                        );
                      } else {
                        setLoadingGroup(false);
                      }
                    });
                  }
                  actions(t, selectedTab, []);
                });
              }}
            >
              {t("Refresh")}
            </Button>
          );
          if (multipleSelection) {
            render.push(
              <DeleteGroups
                key="deleteGroups"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>{t("Delete")}</Button>
                )}
                groupsId={selectedItems}
                reloadData={(data) => {
                  handleGroup(data);
                  actions(t, selectedTab, []);
                }}
              />
            );
          } else {
            render.push(
              <CreateGroup
                key="createGroup"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>{t("Add")}</Button>
                )}
                reloadData={(newGroup) => {
                  handleGroup([newGroup]);
                  actions(t, selectedTab, []);
                }}
              />
            );
            render.push(<ImportGroup
              key="importGroup"
              TriggerComponent={(onClick, loading) => (
                <Button loading={loading} onClick={() => onClick()}>
                  {t("Import")}
                </Button>
              )}
              reloadData={(data) => {
                handleGroup(data);
                actions(t, selectedTab, []);
              }}
            />);
          }
          setActionsBar(render);
          break;
        case "schools":
          render.push(
            <Button
              key="refreshSchools"
              loading={loadingSchool}
              onClick={() => {
                setLoadingSchool(true);
                setSchools([]);
                setAmountSchools(0);
                isUserAdmin().then((isAdmin) => {
                  if (isAdmin) {
                    getSchools().then((schools) => {
                      if (schools && schools.length) {
                        setSchools(schools);
                        setAmountSchools(schools.length);
                        setLoadingSchool(false);
                      } else {
                        setLoadingSchool(false);
                      }
                    });
                  }
                });
                actions(t, selectedTab, []);
              }}
            >
              {t("Refresh")}
            </Button>
          );
          if (admin) {
            render.push(
              <CreateSchool
                key="createschool"
                TriggerComponent={(onClick) => (
                  <Button onClick={() => onClick()}>{t("Add")}</Button>
                )}
                reloadData={() => {
                  handleSchool();
                  actions(t, selectedTab, []);
                }}
              />
            );
          }
          setActionsBar(render);
          break;
        default:
          setActionsBar(render);
          break;
      }
    },
    [
      loadingStudent,
      school,
      loadingStaff,
      selectedTab,
      staffs,
      loadingGroup,
      loadingSchool,
      admin,
      handleGroup,
      handleSchool,
      handleStaffs,
      handleStudents,
    ]
  );

  useEffect(() => {
    if (!initOnce) {
      isUserAdmin().then((isAdmin) => {
        setAdmin(isAdmin);

        if (isAdmin) {
          getSchools().then((schools) => {
            setSchools(schools);
            setAmountSchools(schools.length);
            if (schools && schools.length) {
              var schoolPreselected = schools[0];
              setSchool(schoolPreselected);
              getStudents(
                schools.map((sch) => sch.uai),
                setAmountStudents,
                false
              );
              getStaffs(
                schools.map((sch) => sch.uai),
                setAmountStaffs,
                false
              );
              getGroups(
                schools.map((sch) => sch.ville),
                setAmountGroups,
                false
              );

              if (schoolPreselected && schoolPreselected.uai) {
                getStudents([schoolPreselected.uai], null, true).then(
                  (studentsAPI) => {
                    if (studentsAPI && studentsAPI.length) {
                      studentsAPI.map((s) => {
                        s.school = schools.find((sch) => sch.uai === s.state)
                          ? schools.find((sch) => sch.uai === s.state).intitule
                          : null;
                        return s;
                      });
                      setStudents(studentsAPI);

                      setActionsBar([
                        <Button
                          key="refreshStudents"
                          loading={loadingStudent}
                          onClick={() => {
                            setLoadingStudent(true);
                            setStudents([]);
                            setSelectedStudents([]);
                            setAmountStudents(0);
                            isUserAdmin().then((isAdmin) => {
                              if (isAdmin) {
                                getSchools().then((schools) => {
                                  if (schools && schools.length) {
                                    getStudents(
                                      schools.map((sch) => sch.uai),
                                      setAmountStudents,
                                      false
                                    );
                                    if (
                                      schoolPreselected &&
                                      schoolPreselected.uai
                                    ) {
                                      getStudents(
                                        [schoolPreselected.uai],
                                        null,
                                        true
                                      ).then((studentsAPI) => {
                                        if (studentsAPI && studentsAPI.length) {
                                          studentsAPI.map((s) => {
                                            s.school = schools.find(
                                              (sch) => sch.uai === s.state
                                            )
                                              ? schools.find(
                                                (sch) => sch.uai === s.state
                                              ).intitule
                                              : null;
                                            return s;
                                          });
                                          setStudents(studentsAPI);
                                        }
                                        setLoadingStudent(false);
                                      });
                                    } else {
                                      setLoadingStudent(false);
                                    }
                                  } else {
                                    setLoadingStudent(false);
                                  }
                                });
                              }
                            });
                            actions(t, selectedTab, []);
                          }}
                        >
                          {t("Refresh")}
                        </Button>,
                        <CreateStudent
                          key="CreateStudent"
                          TriggerComponent={(onClick) => (
                            <Button onClick={() => onClick()}>
                              {t("Add")}
                            </Button>
                          )}
                          reloadData={(data) => {
                            var studentsUpdated = studentsAPI;

                            if (data && data.length) {
                              setStudents([]);
                              setSelectedStudents([]);
                              data.forEach((d) => {
                                if (d?.deleted) {
                                  studentsUpdated = studentsUpdated.filter(
                                    (s) => s.id !== d?.id
                                  );
                                } else {
                                  var index = studentsUpdated.findIndex(
                                    (s) => s.id === d?.id
                                  );
                                  if (index !== -1) {
                                    studentsUpdated[index] = d;
                                  } else {
                                    studentsUpdated.push(d);
                                  }
                                }
                              });
                              setStudents(studentsUpdated);
                            }

                            actions(t, selectedTab, []);
                          }}
                        />,
                        <ImportStudent
                          key="importStudent"
                          TriggerComponent={(onClick) => (
                            <Button onClick={() => onClick()}>
                              {t("Import")}
                            </Button>
                          )}
                          reloadData={(data) => {
                            var studentsUpdated = studentsAPI;

                            if (data && data.length) {
                              setStudents([]);
                              setSelectedStudents([]);
                              data.forEach((d) => {
                                if (d?.deleted) {
                                  studentsUpdated = studentsUpdated.filter(
                                    (s) => s.id !== d?.id
                                  );
                                } else {
                                  var index = studentsUpdated.findIndex(
                                    (s) => s.id === d?.id
                                  );
                                  if (index !== -1) {
                                    studentsUpdated[index] = d;
                                  } else {
                                    studentsUpdated.push(d);
                                  }
                                }
                              });
                              setStudents(studentsUpdated);
                              actions(t, selectedTab, []);
                            }
                          }}
                        />,
                      ]);
                    }
                  }
                );

                getStaffs([schoolPreselected.uai], null, true).then(
                  (staffsAPI) => {
                    if (staffsAPI && staffsAPI.length) {
                      staffsAPI.map((s) => {
                        s.school = schools.find((sch) => sch.uai === s.state)
                          ? schools.find((sch) => sch.uai === s.state).intitule
                          : null;
                        return s;
                      });
                      setStaffs(staffsAPI);
                    }
                  }
                );

                getGroups([schoolPreselected.ville], null, true).then(
                  (groups) => {
                    if (groups && groups.length) {
                      setGroups(groups);
                    }
                  }
                );
              }
            }
          });
        } else {
          getSchool().then((school) => {
            setSchool(school);
            if (school && school.uai) {
              getStudents([school.uai], setAmountStudents, true).then(
                (studentsAPI) => {
                  if (studentsAPI && studentsAPI.length) {
                    setStudents(studentsAPI);
                    setActionsBar([
                      <Button
                        key="refreshStudents"
                        loading={loadingStudent}
                        onClick={() => {
                          setLoadingStudent(true);
                          setStudents([]);
                          setSelectedStudents([]);
                          setAmountStudents(0);
                          getSchool().then((school) => {
                            if (school && school.uai) {
                              getStudents(
                                [school.uai],
                                setAmountStudents,
                                true
                              ).then((studentsAPI) => {
                                if (studentsAPI && studentsAPI.length) {
                                  setStudents(studentsAPI);
                                }
                                setLoadingStudent(false);
                              });
                            } else {
                              setLoadingStudent(false);
                            }
                          });
                          actions(t, selectedTab, []);
                        }}
                      >
                        {t("Refresh")}
                      </Button>,
                      <CreateStudent
                        key="CreateStudent"
                        TriggerComponent={(onClick) => (
                          <Button onClick={() => onClick()}>{t("Add")}</Button>
                        )}
                        reloadData={(data) => {
                          var studentsUpdated = studentsAPI;
                          setSelectedStudents([]);
                          if (data && data.length) {
                            data.forEach((d) => {
                              if (d?.deleted) {
                                studentsUpdated = studentsUpdated.filter(
                                  (s) => s.id !== d?.id
                                );
                              } else {
                                var index = studentsUpdated.findIndex(
                                  (s) => s.id === d?.id
                                );
                                if (index !== -1) {
                                  studentsUpdated[index] = d;
                                } else {
                                  studentsUpdated.push(d);
                                }
                              }
                            });
                            setStudents(studentsUpdated);
                          }
                          actions(t, selectedTab, []);
                        }}
                      />,
                      <ImportStudent
                        key="importStudent"
                        TriggerComponent={(onClick) => (
                          <Button onClick={() => onClick()}>
                            {t("Import")}
                          </Button>
                        )}
                        reloadData={(data) => {
                          var studentsUpdated = studentsAPI;
                          setSelectedStudents([]);
                          setStudents([]);
                          if (data && data.length) {
                            data.forEach((d) => {
                              if (d?.deleted) {
                                studentsUpdated = studentsUpdated.filter(
                                  (s) => s.id !== d?.id
                                );
                              } else {
                                var index = studentsUpdated.findIndex(
                                  (s) => s.id === d?.id
                                );
                                if (index !== -1) {
                                  studentsUpdated[index] = d;
                                } else {
                                  studentsUpdated.push(d);
                                }
                              }
                            });
                            setStudents(studentsUpdated);
                          }
                        }}
                      />,
                    ]);
                  }
                }
              );
              getMe().then(me => {
                setIsResponsable(!(school?.responsable_role === 1 || school?.administrateur === me.mail))
                if (school?.responsable_role === 1 || school?.administrateur === me.mail) {
                  getStaffs([school.uai], setAmountStaffs, true).then(
                    (staffsAPI) => {
                      if (staffsAPI && staffsAPI.length) {
                        setStaffs(staffsAPI);
                      }
                    }
                  );
                }
              })


              getGroups([school.ville], setAmountGroups, true).then(
                (groups) => {
                  if (groups && groups.length) {
                    setGroups(groups);
                  }
                }
              );
            }
          });
        }
      });
      setInitOnce(true);
    }
  }, [initOnce, t, loadingStudent, actions, selectedTab]);

  const handleChangeTab = useCallback((value) => {
    setSelectedGroups([]);
    setSelectedStaffs([]);
    setSelectedStudents([]);
    setSelectedTab(value);
  }, []);

  const handleChangeSchool = useCallback(
    (uai) => {
      setSchool({});
      var schoolSelected = schools.find((sch) => sch.uai === uai);
      if (schoolSelected && schoolSelected.uai) {
        setSchool(schoolSelected);
        setStudents([]);
        setStaffs([]);
        setGroups([]);
        getStudents([schoolSelected.uai], null, true).then((studentsAPI) => {
          if (studentsAPI && studentsAPI.length) {
            studentsAPI.map((s) => {
              s.school = schools.find((sch) => sch.uai === s.state)
                ? schools.find((sch) => sch.uai === s.state).intitule
                : null;
              return s;
            });
            setStudents(studentsAPI);
          }
        });

        getStaffs([schoolSelected.uai], null, true).then((staffsAPI) => {
          if (staffsAPI && staffsAPI.length) {
            staffsAPI.map((s) => {
              s.school = schools.find((sch) => sch.uai === s.state)
                ? schools.find((sch) => sch.uai === s.state).intitule
                : null;
              return s;
            });
            setStaffs(staffsAPI);
          }
        });

        getGroups([schoolSelected.ville], null, true).then((groups) => {
          if (groups && groups.length) {
            setGroups(groups);
          }
        });
      }
    },
    [schools]
  );

  return !admin && (!school || !school.uai) ? (
    <div className="w-screen">
      <Card>
        {t("No school configured for your account. Please contact your admin.")}
      </Card>
    </div>
  ) : (
    <div className="w-screen">
      <div className="relative py-5 flex items-center justify-center lg:justify-between w-full">
        <div>
          <div>
            <dl
              className={
                admin
                  ? "rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4"
                  : "rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5"
              }
            >
              {!admin && (
                <div className="flex flex-col border-b border-gray-100 p-2 text-center sm:border-0 sm:border-r col-span-2">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className=" sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t("Name")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {school && school.intitule}
                      </dd>
                    </div>
                    <div className=" sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t("Code")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {school && school.uai}
                      </dd>
                    </div>
                    <div className=" sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t("Mail")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {school && school.domaine}
                      </dd>
                    </div>
                    <div className=" sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {t("City")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {school && school.ville}
                      </dd>
                    </div>
                  </dl>
                </div>
              )}
              <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                  {" "}
                  {t("Students")}
                </dt>
                <dd className="order-1 text-5xl font-extrabold text-green-600">
                  {amountStudents}
                </dd>
              </div>
              {(!isResponsable || admin) && <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                  {t("Staffs")}
                </dt>
                <dd className="order-1 text-5xl font-extrabold text-green-600">
                  {amountStaffs}
                </dd>
              </div>}
              <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                  {t("Groups")}
                </dt>
                <dd className="order-1 text-5xl font-extrabold text-green-600">
                  {amountGroups}
                </dd>
              </div>
              {admin && (
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {t("Schools")}
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-green-600">
                    {amountSchools}
                  </dd>
                </div>
              )}
            </dl>
          </div>
          <div className="mt-3" />
          <div className="mx-auto w-screen overflow-x-auto pb-7">
            <Card>
              <TabsUnderline
                tabs={tabs(t, admin, isResponsable, school)}
                selectedTab={selectedTab}
                setSelectedTab={(value) => {
                  handleChangeTab(value);
                  actions(t, value, []);
                }}
                actions={actionsBar}
              />
              {selectedTab === "staffs" && (
                <Table
                  key="staffs"
                  data={staffs}
                  actions={
                    admin && [
                      <Select
                        key="selectSchoolStaffs"
                        value={school && school.uai}
                        onClick={handleChangeSchool}
                        options={schools}
                        keyValue="uai"
                        keyName="intitule"
                      />,
                    ]
                  }
                  columns={(dataFiltered) =>
                    columnsStaffs(
                      t,
                      selectedStaffs,
                      (value) => {
                        setSelectedStaffs(value);
                        actions(t, selectedTab, value);
                      },
                      staffs,
                      (data) => {
                        handleStaffs(data);
                        actions(t, selectedTab, []);
                      },
                      admin,
                      dataFiltered
                    )
                  }
                  handleFilterChange={() => {
                    setSelectedStaffs([]);
                    actions(t, selectedTab, []);
                  }}
                />
              )}
              {selectedTab === "students" && (
                <Table
                  key="students"
                  data={students}
                  actions={
                    admin && [
                      <Select
                        key="selectSchoolStudents"
                        value={school && school.uai}
                        onClick={handleChangeSchool}
                        options={schools}
                        keyValue="uai"
                        keyName="intitule"
                      />,
                    ]
                  }
                  columns={(dataFiltered) =>
                    columnsStudents(
                      t,
                      selectedStudents,
                      (value) => {
                        setSelectedStudents(value);
                        actions(t, selectedTab, value);
                      },
                      students,
                      (data) => {
                        handleStudents(data);
                        actions(t, selectedTab, []);
                      },
                      admin,
                      dataFiltered
                    )
                  }
                  handleFilterChange={() => {
                    setSelectedStudents([]);
                    actions(t, selectedTab, []);
                  }}
                />
              )}
              {selectedTab === "groups" && (
                <Table
                  key="groups"
                  data={groups}
                  actions={
                    admin && [
                      <Select
                        key="selectSchoolGroups"
                        value={school && school.uai}
                        onClick={handleChangeSchool}
                        options={schools}
                        keyValue="uai"
                        keyName="intitule"
                      />,
                    ]
                  }
                  columns={(dataFiltered) =>
                    columnsGroups(
                      t,
                      selectedGroups,
                      (value) => {
                        setSelectedGroups(value);
                        actions(t, selectedTab, value);
                      },
                      groups,
                      (data) => {
                        handleGroup(data);
                        actions(t, selectedTab, []);
                      },
                      dataFiltered
                    )
                  }
                  handleFilterChange={() => {
                    setSelectedGroups([]);
                    actions(t, selectedTab, []);
                  }}
                />
              )}
              {selectedTab === "schools" && (
                <Table
                  key="schools"
                  data={schools}
                  columns={columnsSchools(t, false, (data) => {
                    handleSchool(data);
                    actions(t, selectedTab, []);
                  })}
                />
              )}
              {selectedTab === "licences" && (
                <Table
                  key="licences"
                  data={school.licences.map(l => {
                    var nameLicence = MSLicencesCSV.find(cd => cd.skuId === l.id_licence) ? MSLicencesCSV.find(cd => cd.skuId === l.id_licence).name : l.id_licence
                    var amountAvailableLc = l.amount
                    if (staffs && staffs.length > 0 && staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === l.id_licence))?.length > 0) {
                      amountAvailableLc = l.amount - staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === l.id_licence)).length;
                    }
                    var amountUsedLc = 0
                    if (staffs && staffs.length > 0 && staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === l.id_licence))?.length > 0) {
                      amountUsedLc = staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === l.id_licence)).length;
                    }
                    l.nb_available = amountAvailableLc
                    l.nb_used = amountUsedLc
                    l.name = nameLicence
                    return l;
                  })}
                  columns={columnsSchoolLicences(t)}
                  actions={admin && [
                    <Select
                      key="selectSchoolLicences"
                      value={school && school.uai}
                      onClick={handleChangeSchool}
                      options={schools}
                      keyValue="uai"
                      keyName="intitule"
                    />,
                  ]}
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(withRouter(Main));
