export default function columnsSchoolLicences(t) {
    return [
        {
            key: "name",
            visible: true,
            label: t("Nom"),
            id: "name",
            exportableCSV: true,
            width: 300,
            format: (licence) => <span>{licence.name}</span>,
        },
        {
            key: "nb_available",
            visible: true,
            label: t("Nombre disponible"),
            id: "nb_available",
            exportableCSV: true,
            width: 300,
            format: (licence) => {
                return <span>{licence.nb_available}</span>
            },
        },
        {
            key: "nb_used",
            visible: true,
            label: t("Nombre utilisé"),
            id: "nb_used",
            exportableCSV: true,
            width: 300,
            format: (licence) => {
                return <span>{licence.nb_used}</span>
            },
        },
        {
            key: "amount",
            visible: true,
            label: t("Nombre assigné par l'administrateur"),
            id: "amount",
            exportableCSV: true,
            width: 300,
            format: (licence) => {
                return <span>{licence.amount}</span>
            },
        },

    ];
}