import React, { useContext, useEffect, useState } from "react";
import Structure from "../../../components/Forms/Structure";
import { MSLicencesContext } from "../../../App";
import Button from "../../../components/Buttons/Button";
import { withTranslation } from "react-i18next";
import { assignLicence, } from "../../requests/licences";
import cogoToast from "cogo-toast";
import { getStaffs } from "../../requests/user";
import { XIcon } from "@heroicons/react/outline";

function AddLicence({ t, staff, school, returnFromForm }) {
    const MSLicencesCSV = useContext(MSLicencesContext)
    const [loading, setLoading] = useState(true)
    const [licences, setLicences] = useState([])
    const [staffs, setStaffs] = useState([])
    const [createNewLicence, setCreateNewLicence] = useState({ licence: null })
    const [amountAvailable, setAmountAvailable] = useState(0)

    useEffect(() => {
        setLoading(true)
        getStaffs([school.uai], null, true).then(res => {
            setLoading(false)
            setStaffs(res)
        })

        setLicences(school.licences)
    }, [school])

    const handleAssignLicence = () => {
        assignLicence(staff.id, createNewLicence.licence).then(res => {
            if (res.ok) {
                returnFromForm(createNewLicence.licence)
                cogoToast.success(t("Licence assignée avec succès"),
                    { position: "bottom-right" })
            } else {
                cogoToast.error(t("Erreur lors de l'assignation de la licence"),
                    { position: "bottom-right" })
            }
        })
    }

    return loading ? null : <div className="flex justify-between bg-gray-100 py-1">
        <Structure
            id="licence"
            title={t("Choix de la licence à assigner")}
            component={() => (
                <select
                    name="licence"
                    id="input-licence"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    onChange={e => {
                        var skuId = e.target.value;
                        var licence = licences.find(li => li.id_licence === skuId);
                        if (licence) {
                            var amountAvailableLc = licence.amount
                            if (staffs && staffs.length > 0 && staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === licence.id_licence))?.length > 0) {
                                amountAvailableLc = licence.amount - staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === licence.id_licence)).length;
                            }
                            setCreateNewLicence({ licence: skuId });
                            setAmountAvailable(amountAvailableLc)
                        } else {
                            setCreateNewLicence({ licence: skuId });
                            setAmountAvailable(0)
                        }

                    }}
                >
                    <option />
                    {licences.filter(l => !staff.assignedLicenses.find(al => al.skuId === l.id_licence)).sort((a, b) => a.id_licence > b.id_licence  ? 1 : -1).map(licence => {
                        var amountAvailableLc = licence.amount
                        if (staffs && staffs.length > 0 && staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === licence.id_licence))?.length > 0) {
                            amountAvailableLc = licence.amount - staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === licence.id_licence)).length;
                        }
                        return <option key={licence.id_licence} value={licence.id_licence}>
                            {MSLicencesCSV.find(cd => cd.skuId === licence.id_licence) ? MSLicencesCSV.find(cd => cd.skuId === licence.id_licence).name : licence.id_licence} ( disponible : {amountAvailableLc}/{licence.amount})
                        </option>
                    }
                    )}
                </select>
            )} />
        <div className="flex items-center justify-end py-4">
            <Button
                type="button"
                disabled={!createNewLicence.licence || amountAvailable <= 0}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => {
                    handleAssignLicence()
                }}
            >
                {t("Assigner")}
            </Button>
            <XIcon className="h-6 w-6 text-gray-500 cursor-pointer" onClick={() => returnFromForm(false)} />
        </div>
    </div>
}

export default React.memo(withTranslation()(AddLicence));