import React, { useContext, useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import { getUsersbyIds, getStaffs } from "../../requests/user";
import Table from "../../../components/Tables/Table";
import Button from "../../../components/Buttons/Button";
import Structure from "../../../components/Forms/Structure";
import { MSLicencesContext } from "../../../App";
import columnsUsersLicences from "../../tables/UserLicences";
import cogoToast from "cogo-toast";
import { assignLicenceUsers, removeLicenceUsers } from "../../requests/licences";

const HandleLicenceUsers = ({ t, TriggerComponent, usersId, school, handleReturn }) => {
    const MSLicencesCSV = useContext(MSLicencesContext)

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [licences, setLicences] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [licenceSelected, setLicencesSelected] = useState(null);
    const [actionSelected, setActionSelected] = useState(null);

    function handleOnOpen() {
        setLoading(true)
        getUsersbyIds(usersId).then(usersAPI => {
            setUsers(usersAPI.value)
        })
        setLicences(school.licences)

        getStaffs([school.uai], null, true).then(res => {
            setLoading(false)
            setStaffs(res)
        })
    }

    function handleLicences(setOpen) {
        if (!actionSelected || !licenceSelected) {
            cogoToast.error(t("Veuillez remplir tous les champs"),
                { position: "bottom-right" })
            return
        } else {
            if (usersId.length > 0) {
                if (actionSelected === "assign") {
                    assignLicenceUsers(usersId, licenceSelected).then(res => {
                        if (res.ok) {
                            handleReturn()
                            setOpen(false)
                            cogoToast.success(t("Licence assignée avec succès"),
                            { position: "bottom-right" })
                        } else {
                            cogoToast.error(t("Erreur lors de l'assignation de la licence"),
                            { position: "bottom-right" })
                        }
                    }).catch(() => { cogoToast.error(t("Erreur lors de l'assignation de la licence"),
                        { position: "bottom-right" }) })
                } else if (actionSelected === "remove") {
                    removeLicenceUsers(usersId, licenceSelected).then(res => {
                        if (res.ok) {
                            handleReturn()
                            setOpen(false)
                            cogoToast.success(t("Licence retirée avec succès"),
                            { position: "bottom-right" })
                        } else {
                            cogoToast.error(t("Erreur lors du retrait de la licence"),
                            { position: "bottom-right" })
                        }
                    }).catch(() => { cogoToast.error(t("Erreur lors du retrait de la licence"),
                        { position: "bottom-right" }) })
                }
            } else {
                cogoToast.success(t("Gestion des licences terminée"),
                    { position: "bottom-right" })
                setOpen(false)
            }
        }
    }

    return (
        <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
            {(setOpen) => (
                <div
                    className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
                >
                    <div className="flex-1">
                        <Header title={t("Gestion des licences")} description={t("Veuillez choisir une action et une licence pour agir sur les licences des utilisateurs sélectionnés.")} setOpen={setOpen} />
                        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <Structure
                                id="licence"
                                title={t("Choix de l'action à réaliser")}
                                component={() => (
                                    <select
                                        name="action"
                                        id="input-action"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                                        onChange={e => { setActionSelected(e.target.value); }}
                                    >
                                        <option />
                                        <option value="assign">Assigner une licence</option>
                                        <option value="remove">Retirer une licence</option>
                                    </select>
                                )} />
                            <Structure
                                id="licence"
                                title={t("Choix de la licence")}
                                component={() => (
                                    <select
                                        name="licence"
                                        id="input-licence"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                                        onChange={e => { setLicencesSelected(e.target.value); }}
                                    >
                                        <option />
                                        {licences.sort((a, b) => a.id_licence > b.id_licence ? 1 : -1).map(licence => {
                                            var amountAvailableLc = licence.amount
                                            if (staffs && staffs.length > 0 && staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === licence.id_licence))?.length > 0) {
                                                amountAvailableLc = licence.amount - staffs.filter(st => st.assignedLicenses.find(aL => aL.skuId === licence.id_licence)).length;
                                            }
                                            return <option key={licence.id_licence} value={licence.id_licence}>
                                                {MSLicencesCSV.find(cd => cd.skuId === licence.id_licence) ? MSLicencesCSV.find(cd => cd.skuId === licence.id_licence).name : licence.id_licence} ( disponible : {amountAvailableLc}/{licence.amount})
                                            </option>
                                        }
                                        )}
                                    </select>
                                )} />
                            <Table key="userslicences" data={users} columns={columnsUsersLicences(t)} />
                        </div>
                    </div>
                    <Footer>
                        <Button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            onClick={() => setOpen(false)}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            loading={loading}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            onClick={() => { handleLicences(setOpen) }}
                        >
                            {t("Executer")}
                        </Button>
                    </Footer>
                </div>
            )}
        </WideDrawer>

    );
};

export default React.memo(withTranslation()(HandleLicenceUsers));
